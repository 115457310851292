/* ----------------------------------------------
   TASKS
------------------------------------------------- */
.task-group {
  margin:0 0 10px;
}
.widget-sm .task-group {
  margin-bottom:0;
}
.task-item {
  overflow: hidden;
}
.task-group-heading {
  font-family: $headings-font-family;
  font-size: 15px;
}
.task-check {
  float:left;
}
.task-info {
  overflow:hidden;
}
.task-title {
  margin:0 15px 0 0;
}
.task-meta {
  display: inline-block;
  margin:0 0 0 -4px;
  white-space:nowrap;
}
.task-date {
  font-size:12px;
  margin-left:18px;
  white-space:nowrap;
}

/* New Task Form */
#new-task-form .bg-default {
  padding-top:10px;
  padding-bottom:10px;
  margin-bottom:-10px;
}

@media (max-width: $screen-sm) {
  #new-task-form .bg-default {
	height:auto!important;
  }
}

@media (min-width: $screen-sm) {
  #new-task-form .bg-default.last {
	border-left:1px solid #ccc;
  }
  .task-date-col {
	text-align:right;
  }
  .task-date {
	margin-left:0;
  }
}

@media (min-width: $screen-md) {
  #new-task-form .bg-default {
	border-left:1px solid #ccc;
	margin-top:-10px;
  }
}
