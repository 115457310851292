/* ----------------------------------------------
   COMPONENTS
------------------------------------------------- */
/* LIST GROUP */
.list-group-item {
  padding: 5px 10px;
}
a.list-group-item {
  text-decoration:none;
}
.list-group-item-default {
  background-color: #eee;
}
.list-group-item input[type="checkbox"] {
  margin-right:5px;
}
.list-group-item-header::before {
  content: none!important;
}
.list-group-item-header .list-group-item-heading {
  margin:0;
  display: block;
}

/* Pagination */
.pagination-xs > li > a, .pagination-xs > li > span {
  padding:2px 6px;
}

/* Media Object */
.media-object {
  max-width:none;
}

/* BREADCRUMB STEPS */
.breadcrumb-step {
  padding: 0;
  background: $component-active-bg;
  list-style: none;
  overflow: hidden;

  >li+li:before {
	padding: 0;
  }
  li {
	float: left;
  }
  li.active a {
	color: #fff;
	background: $brand-info;
  }
  li.completed a {
	color: $state-success-text;
	background: $state-success-bg;
  }
  li.active a:after {
	border-left: 30px solid $brand-info;
  }
  li.completed a:after {
	border-left: 30px solid $state-success-bg;
  }
  li a {
	color: $gray-light;
	text-decoration: none;
	padding: 5px 0 5px 45px;
	position: relative;
	display: block;
	float: left;
  }
  li a:after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
	border-bottom: 50px solid transparent;
	border-left: 30px solid $component-active-bg;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	left: 100%;
	z-index: 2;
  }
  li a:before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
	border-bottom: 50px solid transparent;
	border-left: 30px solid white;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	margin-left: 1px;
	left: 100%;
	z-index: 1;
  }
  li:first-child {
	padding-right: 5px;
  }
  li:first-child a {
	padding-left: 25px;
  }
}

/* BADGE */
.badge {
  font-size: 11px;
  font-weight: 400;
  letter-spacing:0px;
  padding:3px 4px;
  min-width: 18px;
}
.badge-primary,
.mat-badge-content
{
  background-color: $brand-primary!important;
  color:#fff!important;
}
.badge-info {
  background-color: $brand-info!important;
  color:#fff!important;
}
.badge-success {
  background-color: $brand-success!important;
  color:#fff!important;
}
.badge-warning {
  background-color: $brand-warning!important;
  color:#fff!important;
}
.badge-danger,
.mat-badge-warn .mat-badge-content
{
  background-color: $brand-danger!important;
  color:#fff!important;
}
.mat-badge-accent .mat-badge-content {
  background: $grey-light!important;
  color: #fff!important;
}
.btn-xs .badge, .btn-group-xs > .btn .badge {
  padding:2px 4px;
  top:-1px;
}

/* Alerts */
.alert {
  margin-bottom: 10px;

  ul, ol {
	padding-left: 20px;
  }
}

/* Typeahead */
.typeahead {
  width:275px;

  > li > a {
	white-space:normal;
  }
}
/* LOADER */
.loader {
  display:table;
  width:100%;
  height:100%;
}
.loader-body {
  display:table-cell;
  vertical-align:middle;
  width:100%;
  height:100%;
  text-align:center;
}

/* Rev Splitter */
.rev-splitter {
  border: 1px solid $grey-dd;

  .rev-splitter-pane {
	border-right: 1px solid $grey-dd;

	&:last-child {
	  border-right: none;
	}

	.rev-splitter-pane-header,
	.rev-splitter-pane-content,
	.rev-splitter-pane-footer {
	  padding: 10px;
	}

	.rev-splitter-pane-header {
	  border-bottom: 1px solid $grey-dd;

	  .mat-tab-nav-bar {
		border-bottom:none;
	  }
	}

	.rev-splitter-pane-footer {
	  border-top: 1px solid $grey-dd;
	}
  }

  .rev-splitter-list-pane {
	flex-basis: 450px;
	min-width: 300px;
  }

  .rev-splitter-details-pane {
	min-width: 450px;
  }

  .rev-splitter-info-pane {
	min-width: 250px;
	flex-basis: 280px;
  }
}
.rev-splitter.rev-two-way-texting-splitter {
  .rev-splitter-list-pane {
	min-width: 345px;
  }

  .rev-splitter-details-pane {
	min-width: 385px;
  }

  .personImage {
	max-width: 40px;
	max-height: 40px;
  }
}

/* Rev List Group */
.rev-list-group {
  &.mat-list-base {
	.mat-list-item {
	  font-family: $font-family!important;
	  font-size: $font-size!important;
	  color: $text-color;
	  height: auto;

	  .mat-list-item-content {
		padding: 7px 10px!important;

		.mat-list-text {
		  padding-right: 0!important;
		}
	  }
	}
  }

  .mat-divider {
	margin: 0!important;
  }

  .mat-badge-content {
	top: 8px!important;
	right: 10px!important;
  }
}

/* Rev Action Toolbar */
.rev-action-toolbar {
  background: $grey-f9;
  padding: 0.5em;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
  text-align: center;
  white-space: nowrap;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 1030;

  &:not(.canvas-slid) {
	left: 50%!important;
  }
}

/* Rev Chat */
.rev-chat {
  .rev-chat-message {
	.rev-chat-message-body {
	  max-width: 65%;

	  .rev-chat-message-text {
		background-color: $state-default-bg;
		border-radius: $border-radius-base;
		text-align: left!important;
	  }
	}
	&.rev-chat-message-incoming {
	  flex-direction: row;
	  text-align: left;
	}
	&.rev-chat-message-outgoing {
	  flex-direction: row-reverse;
	  text-align: right;

	  .rev-chat-message-body {
		.rev-chat-message-text {
		  background-color: $state-info-bg;
		}
	  }
	}
  }
}
