/* You can add global styles to this file, and also import other style files */
/* Top level scss file. All other partial scss files should get put into here. */
@import 'styles/theme';

@import 'styles/variables';

@import 'ej2-base/styles/bootstrap.scss';
@import 'ej2-angular-base/styles/bootstrap.scss';
@import 'ej2-angular-buttons/styles/bootstrap.scss';
@import 'ej2-angular-calendars/styles/bootstrap.scss';
@import 'ej2-angular-dropdowns/styles/bootstrap.scss';
@import 'ej2-angular-grids/styles/bootstrap.scss';
@import 'ej2-angular-inputs/styles/bootstrap.scss';
@import 'ej2-angular-layouts/styles/bootstrap.scss';
@import 'ej2-angular-lists/styles/bootstrap.scss';
@import 'ej2-angular-navigations/styles/bootstrap.scss';
@import 'ej2-angular-notifications/styles/bootstrap.scss';
@import 'ej2-angular-popups/styles/bootstrap.scss';
@import 'ej2-angular-richtexteditor/styles/bootstrap.scss';
@import 'ej2-angular-schedule/styles/bootstrap.scss';
@import 'ej2-angular-splitbuttons/styles/bootstrap.scss';
@import 'ej2-icons/styles/bootstrap.scss';

@import '@angular/material/legacy-prebuilt-themes/legacy-deeppurple-amber';

@import 'bootstrap-sass/assets/stylesheets/bootstrap';

@import 'font-awesome/scss/font-awesome';

@import 'styles/typography';
@import 'styles/navbar';
@import 'styles/buttons';
@import 'styles/navs';
@import 'styles/panels';
@import 'styles/components';
@import 'styles/forms';
@import 'styles/layout';
@import 'styles/footer';
@import 'styles/login';
@import 'styles/tasks';
@import 'styles/patients';
@import 'styles/pods';
@import 'styles/modals';
@import 'styles/messages';
@import 'styles/flexbox';

@import 'styles/custom_styles';
