/* ----------------------------------------------
   MESSAGES
------------------------------------------------- */

.message-pane {
  .message {
	margin-bottom:10px;
	border:1px solid #ccc;
  }
}
.message-reply {
  border:1px solid #ccc;

  .panel-heading {
	border-bottom-color:#ccc;
  }
}
.message-title {
  margin-bottom:2px;
}

.starred {
  color:gold;
}

.rev-attachments-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
	background-color: $grey-lighter;
	border-radius: 2px;
	display: inline-block;
	padding: 0.35em 0.5em;
	margin: 0.25em 0.5em 0.25em 0;
	min-width: 150px;
	max-width: 300px;
  }

  .e-btn {
	background: none!important;
	border: none;
	line-height: $line-height-base;
	color: $link-color;

	&:hover {
	  color: $link-hover-color;
	}

	.e-btn-icon {
	  font-size: $font-size;
	}
  }
}
