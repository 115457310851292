/* ----------------------------------------------
   NAVS
------------------------------------------------- */
.nav a, .btn, #search-results a, .dropdown-menu a {
  text-decoration:none;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  -o-transition: background-color 400ms ease;
  -ms-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}

/*.dropdown-menu li a {
	white-space:normal;
}*/

/* ----------------------------------------------
   TABS
------------------------------------------------- */
.nav-tabs {
  > li {
	> a {
	  background: none!important;
	  border: none!important;
	  border-radius: 0;
	  border-bottom: 3px solid transparent!important;
	  font-size: $tab-nrml-font-size;

	  &:hover, &:focus {
		background: none!important;
	  }
	}

	&.active {
	  > a {
		color: $tab-active-text-color;
		border-bottom: 3px solid $tab-active-text-color!important;
	  }
	}
  }
}
.nav-tabs.nav-justified > li > a, .btn-tabs .btn-sm {
  white-space:nowrap;
  padding:$padding-small-vertical;
}
.section-tabs {
  background-color:$gray-lightest;
  margin:0!important;
  padding:10px 10px 0;
}

/* ----------------------------------------------
   PAGINATION
------------------------------------------------- */
.pagination {
  margin:10px 0;
}

/* ----------------------------------------------
   SUB NAVS
------------------------------------------------- */
.sub-nav {
  margin-bottom:10px;

  .list-group-item {
	border-top: none;
	border-bottom: none;
	margin-bottom: 0;

	&:first-child {
	  border-top: 1px solid $panel-default-border;
	}
	&:last-child {
	  border-bottom: 1px solid $panel-default-border;
	}
  }

  .fa {
	color:$navbar-inverse-link-hover-bg;
  }
}

.rev-folder-tree > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: $screen-sm) {
  .scrollable-menu {
	height:auto;
	max-height:300px;
	overflow-x:hidden;
	overflow-y:auto;
  }
}
