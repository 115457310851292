/* ----------------------------------------------
   FORMS
------------------------------------------------- */
label {
  font-weight:500;
}
.form-horizontal {
  .form-group {
	margin-left:-5px;
	margin-right:-5px;
  }
  .control-label {
	padding-top:4px;
  }
  .form-group-sm .control-label {
	padding-top:5px;
  }
}
.control-label {
  margin-bottom:inherit;
}
.form-inline {
  label {
	margin-top: 3px;
	vertical-align:middle;
  }
  label.btn {
	margin-top:0;
	vertical-align:inherit;
  }
}
input[type="radio"], input[type="checkbox"] {
  margin-top:2px;
}
.radio-inline, .checkbox-inline {
  margin-top:0!important;
}
.clear-text-field {
  color: #cccccc;
  pointer-events: auto;
  cursor: pointer;
  line-height: $input-height-base!important;
}
.select2-container .select2-choice {
  color: $gray-dark!important;
}
.select2-container.input-xs .select2-choice, .input-group-xs .select2-container .select2-choice {
  height: 22px;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.select2-container.select2-container-disabled  .select2-choice {
  color: $grey!important;
}
.select2-results .select2-highlighted {
  background-color: $component-active-bg!important;
  color: $component-active-color!important;
}
.input-xs {
  border-radius: 4px;
  font-size: 13px;
  height: 28px;
  line-height: 1.5;
  padding: $padding-xs-vertical $padding-xs-horizontal;
}
.input-xs + .form-control-feedback {
  height: 28px;
  line-height: 28px!important;
  width: 28px;
}
.input-sm + .form-control-feedback {
  line-height: $input-height-small!important;
}
.input-lg + .form-control-feedback {
  line-height: $input-height-large!important;
}
form > .radio {
  margin-top:0;
}

.help-block {
  margin-bottom:6px;
}

.form-control-static {
  min-height: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
}

::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.date-range, .input-range {
  display: table!important;
  width: 100%;

  .date-range-input, .input-range-input {
	display: table-cell;
	width: 47.5%;
  }

  .date-range-label, .input-range-label {
	display: table-cell;
	text-align: center;
	vertical-align: middle!important;
	padding: 0 0.25em!important;
	width: 5%!important;

	label {
	  margin: 0!important;
	  padding: 0!important;
	}
  }
}

@media (min-width: $screen-sm) {
  .form-inline .form-group {
	margin:4px 10px 4px 0;
  }
  .form-inline {
	.add-clear-span, .has-feedback {
	  display: inline-block;
	  vertical-align: middle;
	}
  }
}

.bootstrap-timepicker .input-group-addon i {
  width:auto; height:auto;
}

.bootstrap-timepicker .rev-timepicker-addon-color-override {
  background-color: #fff;
}
.bootstrap-timepicker .rev-timepicker-addon-color-override[disabled="disabled"] {
  background-color: #eee;
}

.control-label-deprecated {
  color: $text-color;
}
