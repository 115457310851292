/* ----------------------------------------------
   PATIENTS
------------------------------------------------- */
#patient-info-panel {
  .media-heading {
	margin:3px 0 3px;
  }
  .btn, .list-inline > li {
	margin:1px 0;
  }
  .personImage {
	max-width: 60px;
	max-height: 60px;
  }
  .list-inline {
	line-height: 1;
  }
  button.alert-modal {
	line-height: 1;
	padding-left: 4px;
	padding-right: 4px;
  }
}
#patient-info-actions {
  margin:2.5px 0 0;
}
#alert-modal-btn {
  margin:0!important;
}

/* ----------------------------------------------
   CODES
------------------------------------------------- */
.label-icd9 {
  background-color:#64A4CB;
}
.label-icd10 {
  background-color:#64C3CB;
}
.label-snmd {
  background-color:#64CB88;
}

@media (min-width: $screen-sm) {
  #patient-panel {
	#wrap {
	  padding-right:175px;
	}
	#sidebar {
	  width:165px;
	}
	#main {
	  margin: 0 -175px 0 auto;
	}
  }
  .patient-container {
	#main {
	  float: none !important;
	  margin: 0 !important;
	}
  }
}
