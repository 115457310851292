/* ----------------------------------------------
   BUTTONS
------------------------------------------------- */
.btn-primary > .fa {
  color:$navbar-inverse-alt-color;
}

.btn-default {
  &:hover,
  &:focus,
  &.active,
  &.active:hover,
  &.active:focus {
	background-color: $btn-hover-bgcolor;
	color: $grey-white;
  }
}

.e-btn,
.e-css.e-btn {
  &:disabled,
  &.e-disabled {
	opacity: 0.75;
  }
  &.e-link {
	text-decoration: underline;
  }
}

pms-enum-select-button {
  margin-left: 1px;
}

.e-btn-group,
.btn-group {
  .e-btn,
  .btn-default {
	background-color: $grey-white;
	border: 1px solid $btn-bgcolor;

	&:hover,
	&:focus,
	&.active:not(.btn-primary):not(.btn-success):not(.btn-warning):not(.btn-danger),
	&.e-active:not(.e-primary):not(.e-success):not(.e-warning):not(.e-danger) {
	  background-color: $btn-bgcolor;
	  color: $text-color;
	}
  }
}
.e-btn-group .e-btn {
  line-height: 2em;
}
