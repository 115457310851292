/* ----------------------------------------------
   LOGIN
------------------------------------------------- */
body.landing-screen #logo {
  margin-bottom: 20px;
  width:340px;
}

.panel-login {
  .panel-body {
	padding:20px;
  }
  .user-info {
	margin-top:-50px;
  }
  .user-info h4 {
	margin-bottom:12px;
  }
}
.panel-with-avatar {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .panel-login {
	margin-bottom:30px;
	.panel-body {
	  padding:30px;
	}
	.user-info {
	  margin-top:-60px;
	}
  }
}
@media (min-width: 992px) {
  body.landing-screen #logo {
	margin-bottom: 30px;
  }
}
