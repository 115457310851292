/* ----------------------------------------------
   PANELS
------------------------------------------------- */
.panel {
  margin-bottom:10px;
  position: relative;

  .panel {
	margin-bottom:10px!important;
  }

  table {
	margin-bottom:0;
  }

  .navbar {
	margin-bottom:0;
	border-bottom:none;
	z-index:1!important;
  }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	background-color:#fff;
  }

  .nav-tabs, .btn-tabs {
	padding: 10px 10px 0;
  }
}
.panel.with-nav-tabs {
  > .panel-heading {
	padding:0 40px 0 0;
	border-bottom-color:#ccc;

	.pull-right {
	  margin:4px 15px 0;
	}

	> .nav-tabs {
	  border:none;
	  padding:0;
	}
	> .nav-tabs > li {
	  margin-top:0;
	}
	> .nav-tabs > li > a {
	  border-radius:0;
	  padding:9px 10px;
	}
	> .nav-tabs > li:first-child > a {
	  border-top-left-radius:3px;
	  border-left:none;
	}
	> .nav-tabs > li.active > a,
	> .nav-tabs > li.active > a:focus,
	> .nav-tabs > li.active > a:hover {
	  border-color:#ccc #ccc transparent;
	}
  }
}
.panel.widget {
}
.panel.widget-sm {
  margin-bottom:10px;
}
.panel-sm {
  .panel-heading, .panel-footer {
	padding:7px 10px;

	.nav-tools {
	  top:4px;
	}
  }
  .panel-heading {
	padding-bottom: 4px
  }
  > .panel-feature {
	padding:5px 10px 0;
  }
}
.panel-xs {
  .panel-heading, .panel-footer {
	padding: 5px;

	.nav-tools {
	  top: 2px;
	  right: 5px;

	  > li > a {
		padding: 3px 4px;
	  }
	}
  }
  .panel-title {
	font-size: 12px;
  }
  > .panel-feature {
	padding: 5px;
  }
}
.widget-with-btn-group {
  .btn {
	background-color: $grey-f9;
	&:hover,
	&:focus {
	  background-color: $state-default-bg;
	  color: $text-color;
	}
	&.active {
	  background-color: $grey-cc;
	  color: $text-color;
	}
  }
  .btn-group-justified > .btn, .btn-group-justified > .btn-group {
	border-top:none;
	border-bottom:none;
	border-top-left-radius:0;
	border-top-right-radius:0;
  }
  .btn-group + .btn-group .btn {
	border-top:1px solid $btn-default-border;
  }
  .btn-group > .btn:first-child {
	border-left:none;
	border-right:none;
  }
  .btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
	border-right:none;
  }
}
.panel-default.widget-with-btn-group {
  .panel-heading {
	background-color: $state-default-bg;
  }
  .btn {
	border-color:$panel-default-border!important;
  }
}
.panel-info.widget-with-btn-group {
  .btn {
	border-color:$state-info-border!important;
  }
}
.panel-success.widget-with-btn-group {
  .btn {
	border-color:$state-success-border!important;
  }
}
.panel-warning.widget-with-btn-group {
  .btn {
	border-color:$state-warning-border!important;
  }
}
.panel-danger.widget-with-btn-group {
  .btn {
	border-color:$state-danger-border!important;
  }
}
.panel-heading {
  position:relative;
  border-bottom: none;
  padding: 10px 10px 4px;

  h2 {
	font-size:30px;
	font-weight:400;
	line-height:1;
	letter-spacing:-1px;
	margin-top: -5px;
	margin-bottom:-5px;
  }
}
.panel-feature {
  background: none!important;
  border: none!important;
  padding:10px 10px 0;

  .tab-nav {
	margin:4px 0;
  }
}
.panel-feature-sm{
  padding:5px 10px;
}
.panel-footer {
  background:none;

  .pagination {
	margin:0;
	vertical-align:middle;
  }
}
.panel-fixed-footer {
  .panel-footer {
	position:absolute;
	left:0;
	right:0;
	bottom:0;
  }
  .panel-body {
	padding-bottom:45px;
  }
}

.app-panel {
  > .panel-heading > .nav-tabs > li.active > a,
  > .panel-heading > .nav-tabs > li.active > a:focus,
  > .panel-heading > .nav-tabs > li.active > a:hover {
	background-color: $gray-lightest;
  }
}

/* ----------------------------------------------
   DATA PANELS
------------------------------------------------- */
.data-panel {
  background-color: $grey-white;

  .panel-heading, .panel-footer {
	background-color: $grey-white!important;
	padding:5px;

	.form-group {
	  margin-top:2px;
	  margin-bottom:2px;
	}
  }
  .panel-heading {
	border-bottom: 1px solid $panel-default-border;
  }

  .pagination {
	margin:2px 0;
	vertical-align:middle;
  }
}
#bulk-actions-select {
  margin-right:20px;
}

/* Tools Nav */
.nav-tools {
  margin: 0;
  padding: 0;

  > li {
	list-style: none;
	float: left;
	margin: 0 0 0 4px;
	padding: 0;
	line-height: 1;
	position:relative;
  }
  > li > a,
  button,
  .e-btn {
	display: block;
	padding: 5px 6px;
	border: none!important;
	border-radius: $border-radius-base;
	text-decoration: none;
	line-height: 1;
	font-weight: normal;
  }
  > li > a,
  button:not(.e-success),
  .e-btn:not(.e-success) {
	background-color: $btn-bgcolor;
	color: $btn-color;
  }

  > li > a:hover,
  > li > a:active,
  .open > a,
  .open > a:focus,
  .open > a:hover,
  .e-btn:not(.e-success):hover,
  .e-btn:not(.e-success).e-active,
  .e-btn:not(.e-success).e-active:focus,
  .e-btn:not(.e-success).e-active:hover,
  button:not(.e-success):hover,
  button:not(.e-success):active,
  button:not(.e-success):focus
  {
	background-color: $btn-hover-bgcolor;
	color: $btn-hover-color;
  }

  > li > a > i,
  .e-btn .e-btn-icon {
	font-size: 13px;
	font-weight: normal;
	display: inline-block;
	margin-top: 0;
	width: auto;
	vertical-align: middle;
  }

  &.inverse {
	> li > a,
	.e-btn:not(.e-success),
	button:not(.e-success) {
	  background-color: rgba(255, 255, 255, 0.1);
	  color: rgba(255, 255, 255, 0.6);
	}
	> li > a:hover,
	> li > a:active,
	.open > a,
	.open > a:focus,
	.open > a:hover,
	.e-btn:not(.e-success).e-active,
	.e-btn:not(.e-success).e-active:focus,
	.e-btn:not(.e-success).e-active:hover,
	button:not(.e-success):hover,
	button:not(.e-success):active,
	button:not(.e-success):focus {
	  background-color: rgba(255, 255, 255, 0.2);
	}
  }

  /* Fix for Orders Optical Pod */
  > pms-create-order-menu {
	> li {
	  list-style: none;
	  float: left;
	  margin: 0 0 0 4px;
	  padding: 0;
	  line-height: 1;
	  position:relative;
	}
	> li > a {
	  display: block;
	  padding: 5px 6px;
	  border: none!important;
	  border-radius: $border-radius-base;
	  text-decoration: none;
	  line-height: 1;
	  font-weight: normal;
	  background-color: $btn-bgcolor;
	  color: $btn-color;
	}
	> li > a:hover,
	> li > a:active,
	.open > a,
	.open > a:focus,
	.open > a:hover,
	.e-btn:not(.e-success):hover,
	.e-btn:not(.e-success).e-active,
	.e-btn:not(.e-success).e-active:focus,
	.e-btn:not(.e-success).e-active:hover,
	button:not(.e-success):hover,
	button:not(.e-success):active,
	button:not(.e-success):focus {
	  background-color: $btn-hover-bgcolor;
	  color: $btn-hover-color;
	}
	> li > a > i,
	.e-btn .e-btn-icon {
	  font-size: 13px;
	  font-weight: normal;
	  display: inline-block;
	  margin-top: 0;
	  width: auto;
	  vertical-align: middle;
	}
  }
}
.panel-heading .nav-tools {
  position: absolute;
  top: 7px;
  right: 10px;
}

.panel-group {
  margin-bottom: 10px;
  .panel {
	.panel-heading {
	  padding: 10px;
	}
  }
}

@media (max-width: 480px) {
  .panel.with-nav-tabs .panel-heading .pull-right {
	float:none!important;
  }
}

@media (max-width: $screen-sm) {

}

@media (min-width: $screen-sm) {
  .panel {
	.navbar-nav {
	  margin-top:10px;
	}
	.navbar-nav > li > a {
	  padding-top:10px;
	  padding-bottom:10px;
	}
  }
  .panel.widget {
	.panel-body {
	  margin-bottom:10px;
	  height:365px;
	  overflow-y:auto;
	  -webkit-overflow-scrolling: touch;
	}
  }
  .panel.widget-sm {
	.panel-body {
	  height: 225px;
	}
  }
  .panel.widget-sm.with-tabs {
	.panel-body {
	  height:193px!important;
	}
  }
  .panel.widget-sm.widget-with-tabs {
	.panel-body {
	  padding: 0px;
	  margin-bottom: 0px;
	  height: auto!important;
	  overflow-y: visible!important;
	  -webkit-overflow-scrolling: auto!important;

	  .e-tab {
		padding: 2px $panel-body-padding $panel-body-padding;

		.e-content {
		  padding: 0 $panel-body-padding $panel-body-padding;
		  margin-left: -$panel-body-padding;
		  margin-right: -$panel-body-padding;
		  height: 181px!important;
		  overflow-y: auto;
		  -webkit-overflow-scrolling: touch;
		}
	  }
	}
  }
  .patient-container .panel.widget-sm {
	height: 268px;
  }
}
