/* ----------------------------------------------
   NAVBAR
------------------------------------------------- */
/* HEADER */
#header {
  border:none;

  .container-fluid {
	padding-left: 10px;
	padding-right: 10px;
  }

  .navbar-brand > img {
	width:44px;
  }

  .navbar-nav > li > a {
	padding-top: 11px;
	padding-bottom: 11px;
  }
  .nav > li > a > i,
  .navbar-text .divider {
	color:$navbar-inverse-color;
  }
  .navbar-text .divider {
	font-size:11px;
  }

  .navbar-text {
	margin-top:12px;
	margin-bottom:12px;
	font-size: 11px;
  }

  .navbar-toggle {
	margin: 4px 0 4px 8px;
	padding-top: 8px;
	padding-bottom: 8px;
  }

  .navbar-form {
	margin-top:6px;
	margin-bottom:6px;
	position:relative;
  }
  .form-control {
	background-color:$navbar-inverse-link-hover-bg;
	font-size: 12px;
	color:#fff;
	padding:3px 5px 3px 22px;
	height:28px;
	border:none;
  }
  .dropdown-menu .form-control {
	background-color: $navbar-inverse-bg;
  }
  .dropdown-menu > li > rev-external-link > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 400;
	line-height: $line-height-base;
	white-space: nowrap
  }
  .form-control:focus {
	border-color: $navbar-inverse-link-hover-bg;
	-webkit-box-shadow: none;
	box-shadow: none;
  }

  .dropdown-menu .form-control:focus {
	border-color: $navbar-inverse-bg;
  }
  .form-control::-moz-placeholder {
	color: $navbar-inverse-color;
  }
  .form-control:-ms-input-placeholder {
	color: $navbar-inverse-color;
  }
  .form-control::-webkit-input-placeholder {
	color: $navbar-inverse-color;
  }
  .form-control-feedback {
	height:28px;
	width: 22px;
	line-height:28px;
	color:$navbar-inverse-color;
	left: 0;
  }
  .nav > li > a:hover i,
  .nav > li > a:focus i {
	color:$navbar-inverse-alt-color;
  }
  .nav li.active > a i {
	color:$navbar-inverse-link-active-color;
  }
}
.navbar-inverse {
  .navbar-nav > li > a,
  .dropdown-menu > li > a,
  .navbar-text {
	font-size:12px;
	color: $navbar-inverse-alt-color!important;
  }
  .caret {
	color:$navbar-inverse-alt-color!important;
  }
  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus,
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover,
  .dropdown-menu {
	background-color:$navbar-inverse-link-hover-bg;
  }
  .dropdown-menu {
	border:none;
  }
  .dropdown-header {
	color:$navbar-inverse-alt-color;
  }
  .dropdown-menu > li > a {
	color: $navbar-inverse-alt-color;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li.active > a,
  .dropdown-menu .divider {
	background-color:$navbar-inverse-bg;
  }
  .navbar-toggle {
	background-color:$navbar-inverse-bg;
  }
  .dropdown-menu > li > rev-external-link > a {
	color: $navbar-inverse-alt-color !important;
	font-size: 12px;
	&:hover, &:focus {
	  background-color: $navbar-inverse-bg;
	}
  }
  .dropdown-menu > li.active > rev-external-link > a {
	background-color: $navbar-inverse-bg;
  }
}
.navbar-brand {
  padding: 7px 10px;
  height: $navbar-height;
}
.dropdown-header {
  font-size:12px;
  text-transform:uppercase;
}
.navbar-text {
  .divider {
	padding:0 2px;
  }
}
.navbar .app-date-time {
  margin-top:5px;
  margin-bottom: 5px;
}
.navmenu-brand {
  margin:4px 0 6px;
  text-align:right;
}

/* NAV */
.nav-primary {
  > li > a {
	padding: 9px 10px!important;
  }
  > li.active > a {
  }
  li a {
	position: relative;
  }
  > li > a > i {
	font-size:20px!important;
  }
  > li > a > .badge {
	float:right;
  }
}

/* SEARCH */
.navbar-search-results {
  right:0;
  display:block;
  padding-bottom:10px;
  border-top-left-radius:0;
  border-top-right-radius:0;

  a {
	text-decoration:none;
  }

  .search-results-name {
	font-weight:bold;
  }

  .search-results-name, .search-results-phone {
	margin-right:8px;
  }

  .search-results-dob, .search-results-info {
	font-size:11px;
  }

  .search-results-info {
	color:$navbar-inverse-color;
  }
}

.dropdown-menu-open {
  display: block;
}

.rev-header-app .tempSearchDropdown {
  left: -115px;
}

#header-navbar-mobile {
  background-color: $navmenu-inverse-bg;
  display: none;
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  width: $navmenu-width;
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  left: -$navmenu-width;
  right: auto;
  overflow-y: auto;

  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus,
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover,
  .dropdown-menu {
	background-color: $navbar-inverse-bg;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li.active > a,
  .dropdown-menu > li > rev-external-link > a:hover,
  .dropdown-menu > li > rev-external-link > a:focus,
  .dropdown-menu > li.active > rev-external-link > a,
  .dropdown-menu .divider {
	background-color: darken($navbar-inverse-bg, 7%);
  }
}

@media (max-width: 767px) {
  #header {
	left: 0;

	#header-navbar-mobile {
	  &.in {
		left: 0;
		display: block;
	  }

	  .navbar-nav {
		margin: 0;

		> li > a {
		  font-size: $font-size-base;
		  > .nav-name {
			color: $grey-white;
		  }
		  > i {
			width: 1.2857142857em;
			text-align: center;
		  }
		}

		.dropdown-menu {
		  position: static;
		  min-width: 100%;
		  width: 100%;
		  float: none;
		}
	  }
	  .nav-secondary {
		border-top: 1px solid $navbar-inverse-bg;
		margin-top: 6px;
		padding-top: 6px;
		> li > a {
		  padding: $nav-link-padding;
		}
		.todays-patients-btn {
		  i {
			font-size: 1em;
			width: auto;
			text-align: left;

			&.fa-ellipsis-v {
			  margin-left: 1px;
			}
		  }
		}
	  }
	}

	.navbar-form {
	  border-color: $navbar-inverse-bg;
	  margin-left:0;
	  margin-right:0;
	  .form-control {
		background-color:$navbar-inverse-bg;
	  }
	}

	.navbar-search-results {
	  position: static;
	  float: none;

	  a {
		white-space:normal!important;
	  }
	  .search-results-address {
		display:block;
	  }
	}
  }
  body {
	&.canvas-slide {
	  position: relative;
	  left: $navmenu-width;

	  #header {
		left: $navmenu-width;
	  }
	}
  }
}

@media (min-width: $screen-sm) {
  #header {
	.navbar-text.navbar-right {
	  margin-left: 10px;
	  margin-right:10px;
	}
	.form-control {
	  max-width:135px;
	}
  }

  .nav-primary li a .nav-name {
	display: none;
  }
  .nav-secondary > li > a {
	padding-left: 8px;
	padding-right: 8px;
  }
  .nav-primary > li > a > .badge {
	position:absolute;
	top:2px;
	right:2px;
	float:none;
	min-width:15px;
  }
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
	margin-left: -10px;
  }
  .navbar-right {
	float: right !important;
	margin-right: 0;
  }
  .navbar-search-results {
	min-width:220px;
	margin-top:6px;
  }
}

@media (min-width: $screen-lg) {
  .navbar-form {
	padding: 0 5px 0 0;
  }
}

@media (max-width: 991px) {
  .navbar .app-date-time {
	display:none;
  }
}

@media (min-width: $screen-sm) and (max-width: 991px) {
  #header .nav-primary {
	> li > a {
	  padding: 10px 8px !important;

	  > i {
		font-size: 18px !important;
	  }
	}
  }
}

@media (min-width: 1279px) {
  #header .nav-primary > li {
	> a {
	  text-align: center;
	  padding: 5px 6px !important;

	  i {
		font-size: 16px !important;
		margin-bottom: 3px;
	  }

	  .nav-name {
		display: block;
		font-size: 11px;
		line-height: 1;
		color:$navbar-inverse-color;
	  }
	  &:hover .nav-name,
	  &:focus .nav-name {
		color:$navbar-inverse-alt-color;
	  }
	}

	&.active > a .nav-name {
	  color:$navbar-inverse-link-active-color;
	}
  }
}

@media (min-width: 1365px) {
  #header .nav-primary > li {
	> a {
	  padding: 5px 10px !important;
	}
  }
}
