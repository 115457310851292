/* ----------------------------------------------
   MODALS
------------------------------------------------- */
.modal-feature {
  background-color: $grey-f9;
  border-bottom: none;
  padding: 10px;
}

.e-dialog {
  margin:1em;

  .modal-feature {
	padding: 10px 10px 0;
	margin: -10px -10px 10px;
  }

  .e-dlg-content {
	font-weight: normal;
  }

  .e-footer-content {
	.e-btn {
	  margin-left: 0.5em;
	}
	rev-button .e-btn {
	  margin-left: 0;
	}
  }
}

.createTaskModal {
  .modal-dialog {
	width: auto !important;
  }
  .modal-content .panel-body {
	padding: 0;
  }
}

.dockmodal {
  .dockmodal-body {
	communicate-preview {
	  .fr-message-preview-pane {
		width: 100%;
	  }
	}
	.modal-feature {
	  margin: -10px -15px 15px;
	}

	.modal-body {
	  padding: 0;
	}

	.modal-footer {
	  margin: 15px -15px -10px;
	}
  }
}

.dockable-modal {
  border-bottom: none!important;
  position: fixed;
  bottom: 0 !important;
  top: auto !important;
  left: auto !important;
  right: 65px !important;
  z-index: 1031 !important;
  margin: 0 !important;

  .e-dlg-header {
	.header-btns {
	  position: absolute;
	  top: 5px;
	  right: 30px;

	  rev-button {
		margin: 0!important;

		.e-btn {
		  background: none;
		  font-size: $font-size;
		  line-height: 22px;

		  .e-btn-icon {
			font-size: 14px;
			color: rgba(0,0,0,0.5)!important;
		  }

		  &:hover, &:active {
			.e-btn-icon {
			  color: $gray-base!important;
			}
		  }
		}
	  }
	}
  }

  .e-footer-content {
	border-bottom-left-radius: 0!important;
	border-bottom-right-radius: 0!important;
  }

  &.dialog-minimized {
	width: 250px!important;
  }
}
.dockable-modal ~ .dockable-modal {
  right: 320px!important;
}
#todays-patients-dockable-modal.dialog-maximized ~ .dockable-modal {
  right: 570px!important;
}
#patient-letter-dockable-modal {
  &.dialog-maximized ~ .dockable-modal {
	right: 970px!important;
  }

  .fr-wrapper .fr-element {
	height: 570px;
  }
}

@media (max-width: 767px) {
  .dockable-modal.dialog-maximized {
	width: 80%!important;
  }
}

@media (min-width: 768px) {
  .modal-sm {
	width: 400px !important;
  }
  .modal-md {
	width: 750px !important;
  }
}

@media (max-width: 991px) {
  #patient-letter-dockable-modal.dialog-maximized {
	width: 80%!important;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .createTaskModal .modal-dialog {
	width: 85% !important;
  }
}

.e-dlg-container {
  &.modal-lg,
  &.modal-md,
  &.modal-sm {
	width: 100%!important;
  }
}
.e-btn {
  &.modal-lg,
  &.modal-md,
  &.modal-sm {
	width: auto!important;
  }
}
