//needed to compile fontawesome scss
$fa-font-path: "~font-awesome/fonts";

$font-size: 13px;
$text-color: #050505;
$headings-color: #283849;
$link-color: #283849;

$zindex-dropdown: 1050;

$brand-primary:#1AA0A1;
$brand-primary-font:#fff;

$brand-primary-darken-10: darken($brand-primary, 10%);
$brand-primary-darken-15: darken($brand-primary, 15%);
$brand-primary-darken-25: darken($brand-primary, 25%);
$brand-primary-darken-35: darken($brand-primary, 35%);
$grey-base: #000;
$grey-darker: #010101;
$grey-dark: #050505;
$grey: #555;
$grey-light: #777;
$grey-44: #444;
$grey-88: #888;
$grey-99: #999;
$grey-8c: #8c8c8c;
$grey-ad: #adadad;
$grey-dark-font: #fff;
$grey-white: #fff;
$grey-lighter: #eee;
$grey-f9: #f9f9f9;
$grey-f8: #f8f8f8;
$grey-f5: #f5f5f5;
$grey-e6: #e6e6e6;
$grey-dd: #ddd;
$grey-d4: #d4d4d4;
$grey-cc: #ccc;
$grey-light-font: $grey-dark;
$brand-success: #1AA0A1;
$brand-success-dark: darken($brand-success, 10%);
$brand-info: #283849;
$brand-info-dark: darken($brand-info, 10%);
$brand-warning: #f49b01;
$brand-warning-dark: darken($brand-warning, 10%);
$brand-danger: #d21401;
$brand-danger-dark: darken($brand-danger, 10%);
$brand-success-light: #dff0d8;
$brand-info-light: #d9edf7;
$brand-warning-light: #fcf8e3;
$brand-danger-light: #f2dede;
$input-border-focus: #66afe9;
$brand-success-font: #3c763d;
$brand-info-font: #31708f;
$brand-warning-font: #8a6d3b;
$brand-danger-font: #a94442;
$base-font: #000;
$shadow: #000;

$btn-font-size: $font-size;
$btn-text-line-height: 2.155em;
$btn-padding: 0 8px;
$btn-color: $grey-dark;
$btn-bgcolor: $grey-cc;
$btn-border: none;
$btn-border-color: $btn-bgcolor;
$btn-hover-bgcolor: darken($btn-bgcolor, 30%);
$btn-hover-border-color: $btn-hover-bgcolor;
$btn-hover-color: $grey-white;
$btn-focus-bgcolor: $btn-hover-bgcolor;
$btn-focus-border-color: $btn-hover-bgcolor;
$btn-focus-color: $btn-hover-color;
$btn-active-border-color: $btn-hover-bgcolor;
$btn-active-bgcolor: $btn-hover-bgcolor;
$btn-active-color: $btn-hover-color;
$btn-disabled-color: rgba($btn-color, .65);
$btn-disabled-bgcolor: rgba($btn-bgcolor, .65);
$btn-disabled-border-color: rgba($btn-bgcolor, .65);
$btn-success-bgcolor: $brand-success;
$btn-info-bgcolor: $brand-info;
$btn-warning-bgcolor: $brand-warning;
$btn-danger-bgcolor: $brand-danger;
$btn-icon-font-size: $font-size;
$btn-icon-padding: 0 8px;
$btn-link-color: $link-color;
$btn-link-hover-color: darken($link-color, 20%);

$toast-icon-nrml-size: 24px;
$toast-icon-bgr-size: 24px;

$schedule-work-cells-bg-color: $grey-ad;
$schedule-event-box-shadow-1: 0 6px 10px 0 rgba(0, 0, 0, .3);
$schedule-event-box-shadow-2: 0 1px 18px 0 rgba(0, 0, 0, .3);
$schedule-event-box-shadow-3: 0 3px 5px -1px rgba(0, 0, 0, .3);

$grid-font-size: $font-size;
$grid-content-font-size: $font-size;
$grid-content-padding: 5px;
$grid-content-right-padding: 5px;
$grid-header-font-size: $font-size;
$grid-header-padding: 5px;
$grid-headercell-line-height: 32px;
$grid-summary-cell-font-size: $font-size;
$grid-group-caption-font-size: $font-size;

$pager-padding: 6px;
$pager-active-color: $grey-dark;
$pager-active-bg-color: $grey-dd;
$pager-tripledot-padding: 4px 8px 6px;
$pager-numeric-icon-padding: 7px 8px 6px 8px;
$pager-pagecontainer-icon-padding: 8px;
$pager-container-margin: 0;

$input-font-size: $font-size;
$input-font-color: $grey-dark;
$input-text-indent: 8px;
$input-disable-bg-color: $grey-f9;
$input-disable-font-color: $grey;
$input-disabled-group-bg-color: $grey-f9;
$input-normal-height: 28px;
$input-child-min-height: 26px;
$input-child-min-width: 26px;

$datepicker-icon-container-min-height: 30px;

$ddl-list-font-size: $font-size;
$ddl-list-text-indent: 8px;
$ddl-list-padding-right: 8px;
$ddl-check-right: 9px;
$ddl-input-height: $input-child-min-height;
$ddl-input-font-size: $font-size;
$ddl-delim-font-size: $font-size;
$ddl-selectall-font-size: $font-size;

$treeview-root-ul-padding: 0 0 0 14px;
$treeview-rtl-root-ul-padding: 0 14px 0 0;
$treeview-child-ul-padding: 0 0 0 14px;
$treeview-rtl-child-ul-padding: 0 14px 0 0;
$treeview-text-wrap-padding: 0 0 0 18px;
$treeview-rtl-text-wrap-padding: 0 18px 0 0;
$treeview-icon-size: 18px;
$treeview-icon-margin: 0 0 0 -18px;
$treeview-rtl-icon-margin: 0 -18px 0 0;
$treeview-icon-padding: 6px;

$listview-header-font-size: $font-size;
$listview-groupheader-font-size: $font-size;
$listview-font-size: $font-size;
$listview-back-icon-font-size: $font-size;
$listview-template-multiline-content-font-size: $font-size;

$hscroll-device-arrow-size: $font-size;
$hscroll-device-arrow-size-bigger: $font-size;
$vscroll-device-arrow-size: $font-size;

$field-list-drag-clone-font-size: $font-size;
$field-list-icon-size: $font-size;
$field-list-header-icon-size: $font-size;

$grouped-text-font-size: $font-size;

$cbox-font-size: $font-size;

$radio-btn-font-size: $font-size;

$spin-label-font-size: $font-size;

$float-placeholder-font-size: $font-size;

$tab-text-color: $link-color;
$tab-hover-text-color: darken($link-color, 20%);
$tab-active-text-color: $headings-color;
$tab-comb-text-color: $tab-active-text-color;
$tab-comb-icon-color: $tab-active-text-color;
$tab-nrml-font-size: ceil(($font-size + 1));
$tab-nrml-height: 32px;
$tab-focus-nrml-height: $tab-nrml-height;
$tab-active-nrml-height: $tab-nrml-height;
$tab-active-focus-nrml-height: $tab-nrml-height;
$tab-nrml-fill-height: $tab-nrml-height;
$tab-focus-nrml-fill-height: $tab-nrml-height;
$tab-nrml-active-text-container-height: $tab-nrml-height;
$tab-nrml-active-it-text-container-height: $tab-nrml-height;
$tab-fill-nrml-active-it-text-container-height: $tab-nrml-height;
$tab-nrml-even-active-text-container-height: $tab-nrml-height;
$tab-nrml-wrap-padding: 0 10px;
$tab-mob-wrap-padding: $tab-nrml-wrap-padding;
$tab-nrml-io-wrap-padding: $tab-nrml-wrap-padding;
$tab-fill-nrml-wrap-padding: $tab-nrml-wrap-padding;
$tab-mob-fill-wrap-padding: $tab-nrml-wrap-padding;
$tab-bg-nrml-wrap-padding: $tab-nrml-wrap-padding;
$tab-nrml-nav-icon-container-size: $tab-nrml-height;
$tbar-bgr-items-size: $tab-nrml-height;
$tbar-nav-bgr-width: $tab-nrml-height;
$tab-big-height: $tab-nrml-height;
$tab-focus-big-height: $tab-nrml-height;
$tab-active-big-height: $tab-nrml-height;
$tab-active-focus-big-height: $tab-nrml-height;
$tab-big-fill-height: $tab-nrml-height;
$tab-mob-fill-height: $tab-nrml-height;
$tab-focus-big-fill-height: $tab-nrml-height;
$tab-pop-big-icon-top-bottom-item-height: $tab-nrml-height;
$tab-mob-pop-item-height: $tab-nrml-height;
$tab-big-wrap-padding: $tab-nrml-wrap-padding;
$tab-big-io-wrap-padding: $tab-nrml-io-wrap-padding;
$tab-fill-big-wrap-padding: $tab-fill-nrml-wrap-padding;
$tab-bg-big-wrap-padding: $tab-bg-nrml-wrap-padding;
$tab-big-active-text-container-height: $tab-nrml-height;
$tab-big-active-it-text-container-height: $tab-nrml-height;
$tab-fill-big-active-it-text-container-height: $tab-nrml-height;
$tab-big-even-active-text-container-height: $tab-nrml-height;
$tab-big-font-size: $tab-nrml-font-size;
$tab-big-even-active-text-height: $tab-nrml-height;
$tab-fill-big-even-active-text-height: $tab-nrml-height;
$tab-big-nav-icon-container-size: $tab-nrml-nav-icon-container-size;

$sidebar-background: $grey-f9;

$dialog-header-font-color: $headings-color;
$dialog-header-content-padding: 10px;
$dialog-content-font-size: $font-size;
$dialog-content-padding: 10px;
$dialog-content-padding-top: 10px;
$dialog-footer-content-padding: 10px;
$dialog-bg-color: $grey-f9;
$dialog-header-bg-color: $dialog-bg-color;
$dialog-content-bg-color: $dialog-bg-color;
$dialog-footer-bg-color: $dialog-bg-color;
