/* ----------------------------------------------
   PODS
------------------------------------------------- */

/* ----------------------------------------------
   SCHEDULE
------------------------------------------------- */
.appointment-item {
  clear:both;
  padding:4px 0;
  border-bottom:1px solid $gray-lighter;
}
.appointment-time {
  background-color: $gray-lightest;
  font-size:12px;
  text-align:right;
  border-right:4px solid $gray;
  padding:0;
  vertical-align:middle;

  a, span {
	display:block;
  }

  a {
	color: $headings-color;
	padding:6px 10px 6px 2px;
	text-decoration:none;
	width:75px;
  }

  .appointment-time-start {
	font-weight:bold;
  }
}
.appointment-info {
  padding-top:5px;
  padding-bottom:5px;

  .appointment-name a {
	font-weight:bold;
	margin-right:3px;

  }

  .appointment-name-age {
	font-size:11px;
	margin-right:3px;
	white-space:nowrap;
  }

  .appointment-meta {
	font-size:12px;
	clear:both;

	.appointment-type {
	  margin-right:5px;
	}

  }
}
.appointment-provider {
  white-space:nowrap;

  i {
	margin-right:2px;
  }
}

.app-status-icon {
  margin: 0 1px;
}
.appointment-list-filters {
  .appt-status-filter {
	.app-status-icon {
	  margin: 0 4px;
	}
	> div.btn-group {
	  cursor: pointer;
	}
  }
}
.appt-status-filter {
  margin-bottom:10px;
}

@media (max-width: $screen-sm) {
  #schedule-panel .pull-right {
	float:none!important;
  }
  .panel-feature {
	.form-inline {
	  > .form-group:first-child {
		margin-top:0;
	  }
	}
	.form-group {
	  margin-top:10px;
	  margin-right:0;
	}
  }
}
@media (min-width: $screen-sm) {
  .appointment-actions {
	text-align:right;

	.appointment-statuses {
	  display:block;
	}
  }
}


/* ----------------------------------------------
   TASKS
------------------------------------------------- */
.panel.widget .task-meta {
  float:none;
}
